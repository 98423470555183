export const clearAllSquares = () => {
  const allSquares = document.querySelectorAll("[data-square]");
  allSquares.forEach((square) => {
    const singleSquare = square as HTMLElement;

    const squareColor = singleSquare.getAttribute("data-square-color");
    if (squareColor === "black") {
      singleSquare.style.backgroundColor = "#b58863";
    } else if (squareColor === "white") {
      singleSquare.style.backgroundColor = "#f0d9b5";
    }
  });
};
